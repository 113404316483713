import React from "react";

export const GifSelection = ({name}: { name: string }) => {
    const getGif = (name: string) => {
        let gif;

        switch (name) {
            case "Marjan":
                gif = "https://media.giphy.com/media/18WwwgwTyA36G261Dg/giphy.gif";
                break;
            case "Viviënne":
                gif = "https://media.giphy.com/media/kBZBlLVlfECvOQAVno/giphy.gif";
                break;
            case "Celeste":
                gif = "https://media.giphy.com/media/r95kAgBEzeapljl1ft/giphy.gif";
                break;
            case "Gerard":
                gif = "https://media.giphy.com/media/Ub4kWebdWWJP2/giphy.gif";
                break;
            case "Jamy":
                gif = "https://media.giphy.com/media/K9MPm9A3CaSkw/giphy.gif";
                break;
            case "Nikki":
                gif = "https://media.giphy.com/media/AGGz7y0rCYxdS/giphy.gif";
                break;
            case "Ilona":
                gif = "https://media.giphy.com/media/ZFuPxYpT4qYOWnDAno/giphy.gif";
                break;
            case "Mariëlle":
                gif = "https://media.giphy.com/media/AGGz7y0rCYxdS/giphy.gif";
                break;
            case "Bram":
                gif = "https://media.giphy.com/media/3o6nUXVguNBmA88rZe/giphy.gif";
                break;
            case "John":
                gif = "https://media.giphy.com/media/K9MPm9A3CaSkw/giphy.gif?cid=ecf05e47jbfwaxr4pgjfpc7rkd9zwcnuy291zcfuygqwcwro&rid=giphy.gif&ct=g";
                break;
            default:
                gif = "https://media.giphy.com/media/OcZp0maz6ALok/giphy.gif";
                break;
        }

        return gif;
    };

    return (
        <img src={getGif(name)} style={{width: "100%"}} alt={"GIF"}/>
    );
}
