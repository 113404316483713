import { Container, Grid } from "@material-ui/core";
import React from "react";
import { MenuItem } from "./menu-item/menu-item";

interface IMenuItemsProps {
    items: any[];
    handleSelect: (item: any) => void;
    selectedId: string;
}

export const MenuItems = ({items, handleSelect, selectedId}: IMenuItemsProps) => {
    return (
        <Container>
            <Grid container spacing={2} alignContent={"stretch"}>
                {items.map((item: any, index: number) => (
                    <MenuItem key={`menu-item-${index}`} item={item} handleSelect={handleSelect} selectedId={selectedId}
                              index={index}/>
                ))}
            </Grid>
        </Container>
    );
}
