import { Container, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useContext } from "react";
import { FirebaseContext } from "../context/firebase-context-provider";

export const Selected = () => {
    const {users} = useContext(FirebaseContext);

    return (
        <Container>
            <Table style={{width: "100%", textAlign: "center"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Naam</TableCell>
                        <TableCell>Voor</TableCell>
                        <TableCell>Hoofd</TableCell>
                        {/*<th>Na</th>*/}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        users
                            .map((user: any, index: number) => (
                                <TableRow key={`user-${index}`}>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.selected.starter ? "✓" : "✖"}</TableCell>
                                    <TableCell>{user.selected.main ? "✓" : "✖"}</TableCell>
                                    {/*<td>{user.selected.dessert ? "✓" : "✖"}</td>*/}
                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </Container>
    );
}

export default Selected;
