import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import groupBy from "lodash/groupBy";
import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../context/firebase-context-provider";

export const Dashboard = () => {
    const {starters, mains, users} = useContext(FirebaseContext);
    const [selectedStarters, setSelectedStarters] = useState();
    const [selectedMains, setSelectedMains] = useState();

    useEffect(() => {
        setSelectedStarters(groupBy(users, (user: any) => user && user.selected.starter.id));
        setSelectedMains(groupBy(users, (user: any) => user && user.selected.main.id));
    }, [users]);

    return (
        <Container>
            {starters.length > 0 && (
                <Box mt={3}>
                    <Typography variant="h5" gutterBottom align={"center"}>
                        Voorgerechten
                    </Typography>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Gerecht</TableCell>
                                    <TableCell align="right">Gekozen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {starters.map((starter: any) => (
                                    <TableRow key={starter.id}>
                                        <TableCell component="th" scope="row">
                                            {starter.title}
                                        </TableCell>
                                        <TableCell
                                            align="right"> {selectedStarters[starter.id] ? selectedStarters[starter.id].length : 0}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}

            {mains.length > 0 && (
                <Box mt={3} mb={3}>
                    <Typography variant="h5" gutterBottom align={"center"}>
                        Hoofdgerechten
                    </Typography>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Gerecht</TableCell>
                                    <TableCell align="right">Gekozen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mains.map((main: any) => (
                                    <TableRow key={main.id}>
                                        <TableCell component="th" scope="row">
                                            {main.title}
                                        </TableCell>
                                        <TableCell
                                            align="right"> {selectedMains[main.id] ? selectedMains[main.id].length : 0}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Container>
    )
}
