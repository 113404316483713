import { CssBaseline } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import * as firebase from "firebase/app";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { firebaseConfig, FirebaseContextProvider } from "./context/firebase-context-provider";
import { Cards } from "./pages/cards";
import { Dashboard } from "./pages/dashboard";
import Main from "./pages/main";
import Selected from "./pages/selected";
import Starter from "./pages/starter";
import Thanks from "./pages/thanks";
import Users from "./pages/users";

const theme = createTheme({
    palette: {
        primary: {
            light: "#757ce8",
            main: "#3f50b5",
            dark: "#002884",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff7961",
            main: "#f44336",
            dark: "#ba000d",
            contrastText: "#000",
        },
    },
    spacing: 10
});

const App = () => {
    // Toggle to show users or cards
    const canSelect = true;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
                    {/*TODO: Replace with react-firebase*/}
                    <FirebaseContextProvider>
                        <header className="App-header">
                            {/* TODO create header*/}
                        </header>

                        <Switch>
                            {/*TODO: Create container with grid and max size. Use padding for list. Scroll in small container*/}
                            {/*When not selected, use Users. When selected, use cards*/}
                            <Route path={"/"} exact component={canSelect ? Users : Cards}/>

                            {canSelect && [
                                <Route path={"/:id/starter"} component={Starter} key={'route-starter'}/>,
                                <Route path={"/:id/main"} component={Main} key={'route-main'}/>,
                                <Route path={"/:id/thanks"} component={Thanks} key={'route-thanks'}/>
                            ]}

                            <Route path={"/dashboard"} component={Dashboard}/>
                            <Route path={"/selected"} component={Selected}/>
                            <Route path={"/cards"} component={Cards}/>
                        </Switch>
                    </FirebaseContextProvider>
                </FirebaseAuthProvider>
            </Router>
        </ThemeProvider>
    );
}

export default App;
