import React, { useContext } from "react";
import { FirebaseContext, IUser } from "../context/firebase-context-provider";
import "./cards.css"

export const Cards = () => {
    const {users} = useContext(FirebaseContext);

    return (
        <div className="cards">
            {users
                .filter((user: IUser) => user.selected.starter)
                .map((user: IUser) => (
                        <div className="card">
                            <h2>
                                {user.name}
                            </h2>

                            <p className="card__menu-item">
                                {user.selected.starter.title}
                            </p>

                            <div className="card__divider"/>

                            <p className="card__menu-item">
                                {user.selected.main.title}
                            </p>
                        </div>
                    )
                )}
        </div>
    );
};
