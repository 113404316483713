import { Box, Divider, Typography, Container } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MenuItems } from "../components/menu-items/menu-items";
import { FirebaseContext } from "../context/firebase-context-provider";

export const Starter = () => {
    const {starters, selectStarter, selectedUser, getSelectedUser, db} = useContext(FirebaseContext);
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        if (id && db) {
            getSelectedUser(id);
        }
    }, [id, db, getSelectedUser]);

    const handleSelect = (starter: any) => {
        selectStarter(id!, starter);
        history.push(`/${id}/main`);
    };

    const user: any = selectedUser;

    let selectedId = "";
    let name = "";
    if (user) {
        selectedId = user.selected.starter.id;
        name = user.name;
    }

    return (
        <Box mt={2}>
            {/*TODO: Add to app*/}
            <Typography variant="h5" gutterBottom align={"center"}>
                {name}
            </Typography>

            <Divider/>

            <Container maxWidth="sm">
                <Box mt={2}>
                    <MenuItems selectedId={selectedId} items={starters} handleSelect={handleSelect}/>
                </Box>
            </Container>
        </Box>
    )
};

export default Starter;
