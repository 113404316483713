import { auth, database } from "firebase";
import React, { useEffect, useState } from "react";

const defaultValue = {
    db: null,
    users: [],
    starters: [],
    mains: [],
    selectedUser: null,
    getSelectedUser: async (id: string) => {
    },
    selectStarter: async (id: string, item: any) => {
    },
    selectMain: async (id: string, item: any) => {
    },
    resetUser: () => {
    }
};
export const FirebaseContext = React.createContext(defaultValue);

export const firebaseConfig ={
    apiKey: "AIzaSyDDrOosZHnq9bCrh1NvomE9uw3-q5lguaI",
    authDomain: "project-zomer.firebaseapp.com",
    databaseURL: "https://project-zomer.firebaseio.com",
    projectId: "project-zomer",
};

const authenticateUser = async () => {
    try {
        await auth().signInAnonymously();
    } catch (e) {
        alert(`Foutje met inloggen. Maak een screenshot en stuur het even op a.u.b :-) ${e.message}`)
    }
}

export interface IUser {
    id: string;
    name: string;
    selected: any;
}

// Use hooks from react-firebase
// eslint-disable-next-line no-empty-pattern
export const FirebaseContextProvider = ({children}: { children: any }, {}) => {
    const [users, setUsers] = useState([]);
    const [starters, setStarters] = useState([]);
    const [mains, setMains] = useState([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [db, setDb] = useState<any>(null);
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        if (!db) {
            setDb(database());
        }
    }, [db]);

    useEffect(() => {
        if (db && user) {
            getUsers();
            getStarters();
            getMains();

            if (selectedUser) {
                getSelectedUser(selectedUser.id)
            }
        }
        // eslint-disable-next-line
    }, [db, user]);

    useEffect(() => {
        if (!user) {
            authenticateUser();

            auth().onAuthStateChanged((user) => {
                if (user) {
                    setUser(user);
                }
            });
        }
    })

    const getUsers = async () => {
        if (db && !users.length) {
            const u: any = await db.ref("/users").once("value");
            const sortedUsers = u.val().sort((a: any, b: any) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            setUsers(sortedUsers);
        }
    };

    const getStarters = async () => {
        if (db && !starters.length) {
            const starters: any = await db.ref("/starters").once("value");
            setStarters(starters.val());
        }
    };

    const getMains = async () => {
        if (db && !starters.length) {
            const mains: any = await db.ref("/mains").once("value");
            setMains(mains.val());
        }
    };

    const getSelectedUser = async (id: string) => {
        if (db) {
            const val = await db.ref(`/users/${id}`).once("value");
            setSelectedUser(val.val());
        }
    };

    const selectStarter = async (id: string, selectedItem: string) => {
        if (db) {
            const update = {"selected/starter": selectedItem};
            db.ref(`/users/${id}`).update(update);
            getSelectedUser(id);
        }
    };

    const selectMain = async (id: string, selectedItem: string) => {
        if (db) {
            const update = {"selected/main": selectedItem};
            db.ref(`/users/${id}`).update(update);
            getSelectedUser(id);
        }
    };

    const resetUser = () => {
        setSelectedUser(null);
    };

    return (
        <FirebaseContext.Provider value={{
            db,
            users,
            starters,
            mains,
            selectedUser,
            getSelectedUser,
            selectStarter,
            selectMain,
            resetUser
        }}>
            {children}
        </FirebaseContext.Provider>
    );
};
