import { Box, Button, Container, Divider, Grid, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GifSelection } from "../components/gif/gif-selection";
import { MenuItem } from "../components/menu-items/menu-item/menu-item";
import { FirebaseContext } from "../context/firebase-context-provider";

export const Thanks = () => {
    const {selectedUser, getSelectedUser, db, resetUser} = useContext(FirebaseContext);
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        if (id && db) {
            getSelectedUser(id);
        }
    }, [id, db, getSelectedUser]);

    const handleRestart = () => {
        resetUser();
        history.push("/");
    };

    const user: any = selectedUser;
    let name = "";
    if (user) {
        name = user.name;
    }

    return (
        <Box mt={2}>
            {/*TODO: Add to app*/}
            <Typography variant="h5" gutterBottom align={"center"}>
                Goed bezig!
            </Typography>

            <Divider/>

            <Container maxWidth="sm">
                <Box mt={2}>

                    {user && (
                        <Container>
                            <Grid container spacing={2} alignContent={"stretch"}>
                                <MenuItem item={user.selected.starter} index={0}/>
                                <MenuItem item={user.selected!.main} index={1}/>
                            </Grid>

                            <Box mt={2}>
                                <GifSelection name={name}/>
                            </Box>

                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Button onClick={handleRestart} color={"primary"}>
                                    <HomeIcon/>
                                </Button>
                            </Grid>
                        </Container>
                    )}
                </Box>
            </Container>
        </Box>
    )
};

export default Thanks;
