import { Box, Button, Container, Divider, Grid, Grow, TextField, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../context/firebase-context-provider";
import "./users.css";

interface User {
    id: string;
    name: string
}

const Users = () => {
    const {users} = useContext(FirebaseContext);
    const history = useHistory();
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        if (users) {
            setFilteredUsers(users);
        }
    }, [users]);

    const linkToUser = (id: string) => {
        history.push(`/${id}/starter`);
    };

    const handleChange = (event: any) => {
        const filtered = users.filter((user: User) => user.name.toLowerCase().includes(event.target.value.toLowerCase()));
        setFilteredUsers(filtered);
    };

    return (
        <>
            <Box mt={2}>
                <Typography variant="h5" gutterBottom align={"center"}>
                    <Grow in={true} timeout={250}>
                        <TextField id="outlined-basic" label="Zoeken" variant="outlined" onChange={handleChange}/>
                    </Grow>
                </Typography>

                <Divider/>

                <Box mt={2}>
                    <Container maxWidth="sm">
                        <Grid container spacing={2} alignContent={"stretch"}>
                            {filteredUsers.map((user: User, index: number) => (
                                <Grow in={true} timeout={(index + 1) * 150} key={`menu-item-${index}`}>
                                    <Grid item xs={12} md={12}>
                                        <Button variant="outlined" size="large" onClick={() => linkToUser(user.id)}
                                                className="user__button">{user.name}</Button>
                                    </Grid>
                                </Grow>
                            ))}
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    )
};

export default Users;

