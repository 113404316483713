import { Card, CardActions, CardHeader, Grid, Grow, IconButton } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import React from "react";

interface IMenuItemProps {
    index: number;
    item: any;
    handleSelect?: (item: any) => void;
    selectedId?: string;
}

export const MenuItem = ({item, handleSelect, selectedId, index}: IMenuItemProps) => (
    <Grow in={true} timeout={(index + 1) * 500}>
        <Grid item key={`menu-item-${item.id}`} xs={12} md={12}>
            <Card>
                <CardHeader title={item.title} subheader={item.subtitle}/>

                {handleSelect && (
                    <CardActions style={{justifyContent: "flex-end"}}>
                        <IconButton onClick={() => handleSelect(item)}>
                            <FavoriteIcon color={selectedId === item.id ? "primary" : "inherit"}/>
                        </IconButton>
                    </CardActions>
                )}
            </Card>
        </Grid>
    </Grow>
)
